<template>
  <div class="container" v-bind:class="{ 'sign-up-mode': signUpMode }">
    <div class="forms-container">
      <div class="signin-signup">
        <form @submit.prevent="login" class="sign-in-form">
          <h2 class="title">Bienvenido</h2>
          <div class="input-field">
            <i class="fas fa-user"></i>
            <input
              type="email"
              placeholder="Correo"
              required
              v-model.trim="email"
            />
          </div>
          <div class="input-field">
            <i class="fas fa-lock"></i>
            <input
              id="inputcontraseña"
              :type="showPassword ? 'text' : 'password'"
              placeholder="Contraseña"
              minlength="8"
              v-model.trim="password"
            />
            <button class="eyeb" @click.prevent="mostrarcontra">
              <i
                class="fas"
                :class="{
                  'fa-eye': !showPassword,
                  'fa-eye-slash': showPassword,
                }"
              ></i>
            </button>
          </div>
          <input type="submit" value="Inicia sesión" class="btn solid" />
        </form>
        <form @submit.prevent="resetPassword" class="sign-up-form">
          <h2 class="title">Restablecer contraseña</h2>
          <div class="input-field">
            <i class="fas fa-envelope"></i>
            <input
              type="email"
              placeholder="Correo"
              required
              v-model.trim="email"
            />
          </div>
          <input type="submit" class="btn" value="Restablecer" />
        </form>
      </div>
    </div>
    <div class="panels-container">
      <div class="panel left-panel">
        <div class="content">
          <div class="menu">
            <h3 class="logo">Tone<span>laje</span></h3>
          </div>
          <p>Olvidaste tu contraseña o quieres restablecerla Da click aquí</p>
          <button
            class="btn transparent"
            id="sign-up-btn"
            @click.prevent="changeMode"
          >
            Restablecer
          </button>
        </div>
        <img :src="log2" class="image" alt="" />
      </div>
      <div class="panel right-panel">
        <div class="content">
          <div class="menu">
            <h3 class="logo">Tone<span>laje</span></h3>
          </div>
          <p>Ingresa al menu pricipal de datos</p>
          <button
            class="btn transparent"
            id="sign-in-btn"
            @click.prevent="changeMode"
          >
            Inicia sesión
          </button>
        </div>
        <img :src="register2" class="image" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { notification } from "ant-design-vue";
import { reactive, toRefs } from "vue";
import { useStore } from "vuex";

export default {
  name: "Login",
  setup() {
    const store = useStore();
    const state = reactive({
      email: null,
      password: null,
      signUpMode: false,
      log2: require("@/assets/log2.svg"),
      register2: require("@/assets/register2.svg"),
      showPassword: false,
    });
    function changeMode() {
      state.signUpMode = !state.signUpMode;
    }
    async function login() {
      const { email, password } = state;
      await store.dispatch("login", { email, password });
    }
    function mostrarcontra() {
      state.showPassword = !state.showPassword;
    }
    async function resetPassword() {
      const { email } = state;
      const res = await store.dispatch("resetPassword", { email });
      if (res) {
        notification.success({
          message:
            "Se enviaron instrucciones a tu correo para reestablecer tu contraseña.",
        });
        changeMode();
      }
    }
    return {
      ...toRefs(state),
      changeMode,
      mostrarcontra,
      login,
      resetPassword,
    };
  },
  computed: {},
  methods: {},
};
</script>

<style scoped src="../styles/style.css"></style>
